import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Bimetal = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>
						バイメタル式温度計（注文製作品）
						<br class="sp" />
						図面（PDFファイル）
					</h2>
					<div className="content-container">
						<ul className="documents-ul flex wrap">
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-t-40s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-T-40S
								</a>
							</li>

							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-t-60s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-T-60S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-t-70s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-T-70S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-t-75s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-T-75S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-t-90s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-T-90S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-s-60s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-S-60S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-s-70s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-S-70S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-s-90s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-S-90S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-f-70s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-F-70S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-f-90s.pdf" target={"_blank"} rel="noreferrer">
									防滴型 BM-F-90S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-t-100e.pdf" target={"_blank"} rel="noreferrer">
									接点付 BM-T-100E
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-t-100a.pdf" target={"_blank"} rel="noreferrer">
									防水型 BM-T-100A
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bm-t-150P.pdf" target={"_blank"} rel="noreferrer">
									防水型 BM-T-150P
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bimetal-order/bmw-s-100s.pdf" target={"_blank"} rel="noreferrer">
									防水型 BMW-S-100S
								</a>
							</li>
							<p>
								※バイメタル式温度計用保護管は、BM-T-75Sシリーズではご使用できません。
							</p>
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Bimetal;
